import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, lightblue, blue, LeadingText, LeadingTextBlock } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import Landing from './Landing';


// TODO: implement


const ContactPost = ()=>{
    

return (<div>
    Thank you
</div>)
    
}



export default ContactPost;
