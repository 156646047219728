import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import Landing from './Landing';
import { OnsVerhaalContent } from './Onsverhaal';
import { TechniekenContent } from './Technieken';
import { PremiesContent } from './Premies';
import Contact, { ContactContent } from './Contact';

// TODO: implement

const Home = ()=>{
    const [landing, home, verhaal, contact, premie, projects, technieken] 
    = useSelector(state=>[state.data.landing, state.data.home, state.data.verhaal, state.data.contact, state.data.premie, state.data.projects, state.data.technieken]);
    
    

    
    if (!landing || !home || !verhaal || !contact || !premie || !projects || !technieken){
        return null;
    }


    const Meta = <MetaTags>
                    <title>{"MEEtec home"}</title>
                    <meta name="description" content={"Mechelse Energie Technieken - Home"} />
                    <meta property="og:title" content={"MEEtec home"} />
                </MetaTags>
    
    return <>
                {Meta}
                <OnsVerhaalContent /> 
                <TechniekenContent />
                <ContactContent />
                <PremiesContent />

    </>
}



export default Home;
