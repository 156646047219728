import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, lightblue, blue, LeadingText, LeadingTextBlock, ResponsiveImage, TextBlock } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import Landing from './Landing';

// ses-smtp-user.20240401-181831
// AKIAROORUXNERBF7X56B
// BAQCDrATDZpDDRj+qgSCuWZkBbzlAf2WKEowjCW1WDTi

// TODO: implement

export const ContactContent = () => {
    
    const contact = useSelector(state => state.data.contact);

     // Define styles for the form elements
     const formStyle = {
        color: blue, // Sets text color for labels
    };

    const inputStyle = {
        backgroundColor: 'white', // Sets the background color of the input fields to white
        borderColor: blue, // Border color of the input fields
        borderWidth: '1px', // Border width
        borderRadius: '3px', // Rounded borders
        padding: "5px",
        paddingLeft: '10px', // Left padding inside the inputs
        width: '100%', // Makes sure the input fields are full width to align them
        boxSizing: 'border-box', // Ensures paddings and borders are included in the total width and height
        marginBottom: "10px"
    };

    if (!contact){
        return null;
    }
  
    return (
        <div style={{background: lightblue}}>
      <Container >
        <br /><br /><br /><br /><br /><br /><br /><br />
        <Grid container spacing={2}>
          {/* Adjust the grid item sizes as necessary for your design */}
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Title color={blue}>Contact</Title>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={7}>
          <ResponsiveImage src={contact.header} />
            <br/><br/>
            <LeadingTextBlock color={blue} description={contact.description} />
            
            {/* Form starts here */}
            <form name="contact" netlify style={formStyle} method="post" action="/contactpost">
                            <input type="hidden" name="form-name" value="contact" />
                            <p><label color={blue}>Naam* <input type="text" name="name" required style={inputStyle} /></label></p>
                            <p><label>E-mail* <input type="email" name="email" required style={inputStyle} /></label></p>
                            <p><label>Bedrijf <input type="text" name="company" style={inputStyle} /></label></p>
                            <p><label>Adres +nr <input type="text" name="address" style={inputStyle} /></label></p>
                            <p><label>Postcode + Gemeente <input type="text" name="postcode" style={inputStyle} /></label></p>
                            <p><label>Telefoon* <input type="tel" name="phone" required style={inputStyle} /></label></p>
                            <p><label>Bericht* <textarea name="message" required style={{...inputStyle, height: '150px'}}></textarea></label></p>
                            

                            <p><button type="submit" style={{...inputStyle, width: 'auto', cursor: 'pointer'}}>
                                <Text color={blue}>Verzenden</Text>
                                </button></p>
                        </form>

            <TextBlock color={blue} description={contact.post} />
            <br/><br/>
            <div style={{width: '100%', height: '400px', marginTop: '20px', marginLeft: "0px"}}>
            
                            <iframe
                                title="Google Maps Location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.870959696663!2d4.417604712624051!3d51.055470443499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3ef9e37f04b6d%3A0xb8a48dc6c3d7166e!2sHooiendonkstraat%2097%2C%202801%20Mechelen%2C%20Belgium!5e0!3m2!1sen!2sdk!4v1711982244081!5m2!1sen!2sdk"
                                frameBorder="0"
                                style={{border: 0, width: '100%', height: '100%'}}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                            ></iframe>
                        </div>
          </Grid>
        </Grid>
      </Container>
      <br/><br/>
      </div>
    );
  };

const Contact = ()=>{
    
    const contact = useSelector(state=>state.data.contact);


    const Meta = <MetaTags>
                    <title>{"MEEtec contact"}</title>
                    <meta name="description" content={"Mechelse Energie Technieken - contact"} />
                    <meta property="og:title" content={"MEEtec contact"} />
                </MetaTags>
    
    if (!contact){
        return <>
                {Meta}
                <Spinner />
            </>
    } else {
        return <>
                {Meta}
                <ContactContent />

    </>
    }
}



export default Contact;
