export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_HOME: Symbol("data/home"),
    SET_CONTACT: Symbol("data/contact"),
    SET_LANDING: Symbol("data/landing"),
    SET_VERHAAL: Symbol("data/verhaal"),
    SET_PREMIE: Symbol("data/premie"),
    SET_PROJECTS: Symbol("data/projects"),
    SET_TECHNIEKEN: Symbol("data/technieken"),

});

const initialState = {
  client: null,
  landing: null,
  home: null,
  verhaal: null,
  contact: null,
  premie: null,
  projects: null,
  technieken: null,
};

const handler = (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }


    case ACTIONS.SET_HOME: {
      return {
        ...state,
        home: action.home
      };
    }

    case ACTIONS.SET_CONTACT: {
      return {
        ...state,
        contact: action.contact
      };
    }

    case ACTIONS.SET_LANDING: {
      return {
        ...state,
        landing: action.landing
      };
    }

    case ACTIONS.SET_VERHAAL: {
      return {
        ...state,
        verhaal: action.verhaal
      };
    }

    case ACTIONS.SET_PREMIE: {
      return {
        ...state,
        premie: action.premie
      };
    }

    case ACTIONS.SET_PROJECTS: {
      return {
        ...state,
        projects: action.projects
      };
    }

    case ACTIONS.SET_TECHNIEKEN: {
      return {
        ...state,
        technieken: action.technieken
      };
    }

    default: {
      return {
        ...state
      };
    }
  } 
};

export default handler;