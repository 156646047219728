import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box, Button, ResponsiveImage, Title } from '../theme/theme';
import settings from "../settings.json"
import { Link } from 'react-router-dom';
// TODO: implement

const NotFound = () => {
    const [chameleon, setChameleon] = useState(undefined);

    useEffect(()=>{
        if (!chameleon){
            const arr = settings.placeholderImages;
            setChameleon(arr[Math.floor(Math.random()*arr.length)]);
        }
    })

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={false}>
                    <Box>
                        <Grid item md={0}>
                            <Title>
                                <Link to="/"> We're terrible sorry, but the page you requested was not found, go back home?</Link> 
                            </Title>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={0} sm={2} ></Grid>
                <Grid item sm >
                    <Box>
                        <ResponsiveImage src={chameleon} />
                    </Box>
                </Grid>
            </Grid>
        </Container>   
    )
}
export default NotFound;
