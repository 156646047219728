import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, LeadingText, ResponsiveImage, SubTitle, Title, Text, lightyellow, mustardyellow, lightblue, blue, LeadingTextBlock, TextBlock, ImageWithCaption } from '../theme/theme';
import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { yellow } from '@mui/material/colors';
import Onsverhaal from '../pages/Onsverhaal';
import { SubTitles } from '@mui/icons-material';
import { verhaalQuery } from '../client/queries';
import sv from "../email.svg"
// TODO: implement

const Builder = () => {

    const [landing, home, verhaal, contact, premie, projects, technieken] 
    = useSelector(state=>[state.data.landing, state.data.home, state.data.verhaal, state.data.contact, state.data.premie, state.data.projects, state.data.technieken]);
    
    

    useEffect(()=>{
        
    })
    if (!landing || !home || !verhaal || !contact || !premie || !projects || !technieken){
        return null;
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item>
                    <Box>
                        <Grid item md={0}>
                            <Title>
                                Website builder
                            </Title>
                            <div style={{backgroundColor: "pink", padding: "20px"}}>
                            <SubTitle>
                                Colors
                            </SubTitle>
                            <Box>
                                    lightyellow: 
                                    <div style={{width: "10px", height: "10px", backgroundColor: lightyellow}}>
                                        &nbsp; 
                                    </div>
                                    mustardyellow: 
                                    <div style={{width: "10px", height: "10px", backgroundColor: mustardyellow}}>
                                        &nbsp; 
                                    </div>
                                   blue: 
                                    <div style={{width: "10px", height: "10px", backgroundColor: blue}}>
                                        &nbsp; 
                                    </div>
                                    lightblue: 
                                    <div style={{width: "10px", height: "10px", backgroundColor: lightblue}}>
                                        &nbsp; 
                                    </div>
                                
                            </Box>

                            <div style={{backgroundColor: lightyellow}}>
                                <Box>
                                    I am box
                                </Box>
                            </div>

                            <SubTitle>
                                Elements
                            </SubTitle>
                            <Box>
                            <Title color={lightyellow}>
                                I am Title
                            </Title>
                            <SubTitle color={mustardyellow}>
                                I am SubTitle
                            </SubTitle>
                            <LeadingText color={lightblue}>
                                Welkom bij MEEtec, uw partner in energietechnologie. Als vooraanstaande installateur van gascondensatieketels, warmtepompen, zonnepanelen, elektriciteit en laadinfrastructuur, streven we ernaar om uw energiebehoeften te vervullen met geïntegreerde oplossingen die efficiëntie en ecologie combineren.
                            </LeadingText>
                            <Text color={blue}>
                                Welkom bij MEEtec, uw partner in energietechnologie. Als vooraanstaande installateur van gascondensatieketels, warmtepompen, zonnepanelen, elektriciteit en laadinfrastructuur, streven we ernaar om uw energiebehoeften te vervullen met geïntegreerde oplossingen die efficiëntie en ecologie combineren.
                            </Text>
                            </Box>
                            </div>

                            
                        </Grid>
                        <br/><br/><br/>
                        <Grid item>

                            <img src={sv} />

                            <Title>DATA</Title>
                            <SubTitle>landing</SubTitle>
                            
                            <LeadingTextBlock color={mustardyellow} description={landing.description} />
                            <Text>Enable: {landing.enable?"True":"False"}</Text>


                            <SubTitle>home</SubTitle>
                            <LeadingTextBlock color={blue} description={home.footer} />

                            <SubTitle>verhaal</SubTitle>
                            <LeadingTextBlock description={verhaal.description} />

                            <SubTitle>contact</SubTitle>
                            <ResponsiveImage src={contact.header} />
                            <TextBlock description={contact.description} />

                            <SubTitle>premie</SubTitle>
                            

                            {premie.map(({title, description, items})=>{

                            return    <div key={title}>
                                    <Title >{title}</Title>
                                    <LeadingTextBlock description={description} />

                                    {items.map(({title, description, url})=>{
                                    return <div key={title}> 
                                    <SubTitle>{title}</SubTitle>
                                    <a href={url}> link </a>
                                    <TextBlock description={description} />

                                    </div>
                                    
                                    })}
                                </div>
                            })}

                            

                            
                            <SubTitle>techniek</SubTitle>

                            {technieken.map(({title, description, project})=>{
                                return <div key={title}>
                                    <Title>{title}</Title>
                                    <LeadingTextBlock description={description} />
                                    <SubTitle>{project.title}</SubTitle>
                                    <ResponsiveImage src={project.header}/>
                                    <TextBlock description={project.description} />
                                    <TextBlock description={project.site}/>
                                    <i>{project.client}</i>
                                    {project.images.map(({url, description})=><ImageWithCaption key={url} src={url} caption={description} />)}
                                    
                                </div>
                            })}
                            

                        </Grid>


                    </Box>
                </Grid>



                
            </Grid>
        </Container>   
    )
}
export default Builder;
