
import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import { Icon, blue, lightblue, lightyellow, Title, Banner, SubTitle, mustardyellow, BannerLogo} from "../theme/theme";
import { Button, Container, Grid } from '@mui/material';
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, useScrollTrigger, Slide, CssBaseline, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { lightBlue, yellow } from "@mui/material/colors";


function HideOnScroll(props) {
    const { children } = props;
    // Trigger to detect scroll activity
    const trigger = useScrollTrigger();
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  
  function DynamicAppBar(props) {

    const [isScrolled, setIsScrolled] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});
    const [headerHeight, setHeaderHeight] = useState(null); // Initial header height
    const [maxheightmobile, maxheightdesktop] = [150, 250];

    const items = [
        {
            title: "Ons Verhaal",
            link: "/onsverhaal"
        },
        {
            title: "Technieken",
            link: "/technieken"
        },
        {
            title: "Premies",
            link: "/premies"
        },
        {
            title: "Contact",
            link: "/contact"
        },
    ]
  
    const handleScroll = () => {
        
        const minHeight = isDesktop?120:64; // Smaller header height when scrolled
        const maxHeight = isDesktop?maxheightdesktop:maxheightmobile; // Initial header height
        const isPageScrolled = window.scrollY > 0;
        setIsScrolled(isPageScrolled);
        // Calculate dynamic height based on scroll position
        // Adjust these values as needed
        const newHeight = Math.max(minHeight, maxHeight - window.scrollY);
        setHeaderHeight(`${Math.min(newHeight, maxHeight)}px`); // Ensure the header height does not exceed maxHeight
      };
  
      useEffect(() => {

        if (!headerHeight){
            setHeaderHeight(isDesktop?maxheightdesktop:maxheightmobile)
        
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [isDesktop, headerHeight]);
  
    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setIsDrawerOpen(open);
    };

    
  
    const renderMobileMenu = () => (
      <Drawer anchor="left" 
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
                sx: {
                  backgroundColor: blue
                }
              }}
            >
        <Box style={{margin: "40px", width: "150px"}}>
            <Icon filter="filter-mustardyellow" />
        </Box>
        
        <div style={{textAlign: "left"}}>
            <br/>
        {items.map(({title, link})=>{
            return <Box key={link} button onClick={toggleDrawer(false)} style={{marginLeft: "10px", marginBottom: "14px"}} 
            sx={{
                '&:hover': {                  
                  transform: 'scale(1.05)', // Scales up the button on hover
                  transition: 'transform 300ms ease-in-out', // Smooth transition for the transform effect
                },
              }}>
            <Link to={link}>
              <SubTitle color={mustardyellow}>{title}</SubTitle>
              </Link>
        </Box>
        })}
    </div>
      </Drawer>
    );
  
    const renderDesktopMenu = () => (
      <React.Fragment>
        <div style={{textAlign: "left", transform: `scale(${1.0-Math.max(window.scrollY*.003, 0.0)}) translateY(-8px)`, }}>
            <br/>
        {items.map(({title, link})=>{
            return <Box key={link} button onClick={toggleDrawer(false)} style={{marginLeft: "10px"}} 
            sx={{
                '&:hover': {                  
                  transform: 'scale(1.05)', // Scales up the button on hover
                  transition: 'transform 300ms ease-in-out', // Smooth transition for the transform effect
                },
              }}>
            <Link to={link}>
              <SubTitle color={blue}>{title}</SubTitle>
              </Link>
        </Box>
        })}
    </div>

    <div style={{width: "200px", transform: `scale(${1.0-Math.max(window.scrollY*.004, 0.0)})` }}>
        <Link to="/">
            <Box sx={{
                '&:hover': {                  
                  transform: 'scale(.95)', // Scales up the button on hover
                  transition: 'transform 300ms ease-in-out', // Smooth transition for the transform effect
                },
              }}>
                <Icon filter="filter-blue" />
            </Box>
            
        </Link>
    </div>
    <div style={{width: "350px", transform: `scale(${1.0-Math.max(window.scrollY*.0035, 0.0)})`}}>
        <Link to="/">
        <Box sx={{
                '&:hover': {                  
                  transform: 'scale(1.05)', // Scales up the button on hover
                  transition: 'transform 300ms ease-in-out', // Smooth transition for the transform effect
                },
              }}>
            <Banner filter="filter-blue" />
            </Box>
        </Link>
    </div>
    <div style={{width: "10px"}}>

    </div>
        
      </React.Fragment>
    );

    if (!headerHeight){
        return null
    }
  
    return (
        <React.Fragment>
          <CssBaseline />
          <HideOnScroll {...props}>
            <AppBar
              position="fixed"
              elevation={4}
              sx={{
                transition: 'height 0.3s ease', // Smooth transition for height change
                height: headerHeight, // Dynamic height
                backgroundColor: lightyellow,
              }}
            >
              <Toolbar
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {!isDesktop && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    style={{width: `${100-window.scrollY/5}px`}}
                  >
                    <Icon filter="filter-blue" />
                  </IconButton>
                )}
                {isDesktop ? (
                  renderDesktopMenu()
                ) : (
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <div style={{ maxWidth: `${200-window.scrollY}px`, margin: 'auto', transform: `translateY(${9-window.scrollY/100}px)` }} >
                        <Link to="/">
                        <Banner filter={"filter-blue"}  />
                        </Link>
                        </div>
                  </Box>
                )}
              </Toolbar>
            </AppBar>
          </HideOnScroll>
          {!isDesktop && renderMobileMenu()}
          <Toolbar /> {/* Placeholder for content below AppBar */}
        </React.Fragment>
      );
  }

const Header = ()=>(
    <>

    <div style={{background: lightyellow, color: blue, position: "absolute", top: "0", width: "100%"}}>
        <Box sx={{ display: 'flex' }} style={{textAlign: "center", marginTop: "25px", marginBottom: "10px", alignItem: "center", justifyContent: "center"}}>
            <DynamicAppBar />
        </Box>
    </div>
    </>
    
)

export default Header;

