import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';

// TODO: implement

const Landing = ()=>{
    const landing = useSelector(state=>state.data.landing);


    const Meta = <MetaTags>
                    <title>{"MEEtec"}</title>
                    <meta name="description" content={"Mechelse Energie Technieken "} />
                    <meta property="og:title" content={"MEEtec"} />
                </MetaTags>
    
    if (!landing){
        return <>
                {Meta}
                <Spinner />
            </>
    } else {
        return <>
                {Meta}
                <br/><br/><br/>
                <div style={{width: "100vw !important", padding: "0px", margin: "0px", background: "#A2C1C4"}}>
                <Container style={{background: "#A2C1C4", minHeight: "70vh"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={false} lg={12} md={12} sm={12}>
                                <Box>
                                   <BlockContent blocks={landing.description} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>   
                </div>

    </>
    }
}



export default Landing;
