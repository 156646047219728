import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, lightblue, LeadingText, LeadingTextBlock, blue, ResponsiveImage, TextBlock, ImageWithCaption, lightyellow, mustardyellow } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import Landing from './Landing';


// TODO: implement

export const PremiesContent = () => {
    const premies = useSelector(state => state.data.premie);
    const [item, setItem] = useState(null);
    

    useEffect(()=>{
        if (!item && premies && premies.length){
            setItem(premies[0].items[0]);
        }    
    })

    if (!item || !premies){
        return null
    }
  
    return (
        <div style={{background: lightyellow}}>
      <Container >
        <br /><br /><br /><br /><br />
        <Grid container spacing={2}>
          {/* Adjust the grid item sizes as necessary for your design */}
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Title color={blue}>Premies</Title>

            <br/><br/>

            {premies[0].items.map((t)=>{
                return <div  className='interacter' key={t.title} style={{marginBottom: "10px", cursor: "pointer"}} onClick={()=>{setItem(t)}} > <SubTitle color={blue} >  <span style={{textDecoration: item.title == t.title? "underline" : ""}}>{t.title}</span> </SubTitle> </div> 
            })}

            <br/><br/>
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={2} />
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <LeadingTextBlock color={blue} description={item.description} />
            
            <Button external to={item.url} color={blue}>
                Meer informatie
            </Button>
            
          </Grid>
        </Grid>
      </Container>
      <br /><br />
      </div>
    );
  };

const Premies = ()=>{
    
    const premies = useSelector(state=>state.data.premie);


    const Meta = <MetaTags>
                    <title>{"MEEtec premies"}</title>
                    <meta name="description" content={"Mechelse Energie Technieken - Premies"} />
                    <meta property="og:title" content={"MEEtec premies"} />
                </MetaTags>
    
    if (!premies){
        return <>
                {Meta}
                <Spinner />
            </>
    } else {
        return <>
                {Meta}
                <br /><br /><br />
                <div style={{minHeight: "100vh", background: lightyellow}}>
                <PremiesContent />
                </div>

    </>
    }
}



export default Premies;
