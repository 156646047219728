import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, lightblue, blue, LeadingText, LeadingTextBlock } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import Landing from './Landing';


// TODO: implement

export const OnsVerhaalContent = () => {
    const verhaal = useSelector(state => state.data.verhaal);

    if (!verhaal){
        return null;
    }
  
    return (
        <div style={{background: lightblue}}>
      <Container >
        <br /><br /><br /><br /><br /><br /><br /><br />
        <Grid container spacing={2}>
          {/* Adjust the grid item sizes as necessary for your design */}
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Title color={blue}>Ons Verhaal</Title>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={7}>
            <LeadingTextBlock color={blue} description={verhaal.description} />
          </Grid>
        </Grid>
      </Container>
      <br/><br/>
      </div>
    );
  };

const Onsverhaal = ()=>{
    const landing = useSelector(state=>state.data.landing);
    const verhaal = useSelector(state=>state.data.verhaal);


    const Meta = <MetaTags>
                    <title>{"MEEtec verhaal"}</title>
                    <meta name="description" content={"Mechelse Energie Technieken - Verhaal"} />
                    <meta property="og:title" content={"MEEtec verhaal"} />
                </MetaTags>
    
    if (!verhaal){
        return <>
                {Meta}
                <Spinner />
            </>
    } else {
        return <>
                {Meta}
                <OnsVerhaalContent />

    </>
    }
}



export default Onsverhaal;
