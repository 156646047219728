
import {createTheme, Typography, responsiveFontSizes } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {default as MButton} from "@mui/material/Button"
import { Link } from "react-router-dom";
import MeetecIcon from "./meetecicon.svg";
import MeetecBanner from "./meetecbanner.svg";
import MeetecBannerLogo from "./meetecbannerwithlogo.svg";
import BlockContent from '@sanity/block-content-to-react'

const defaultTheme = {
    palette: {
      type: 'light',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#ffff',
      },
    },
    typography: {
      fontFamily: 'Epilogue',
      fontWeight: 100,
      fontSize: 12,
      h1: {
        fontWeight: 800,
        fontSize: '1.8rem',
        textTransform: "uppercase"
      },
      h2: {
        fontSize: '1.7rem',
        fontWeight: 600
      },
      h3: {
        fontWeight: 800,
        fontSize: '2.7rem',
      },
    },
    components: {
        MuiButton: {
          variants: [
            {
              props: { variant: 'hard' },
              style: {
                textTransform: 'none',
                border: `2px solid black`,
                borderRadius: "0px"
              },
            },
          ],
        },
      },
  };

const theme =  responsiveFontSizes(createTheme(defaultTheme));


theme.typography.h1 = {
  '@media (max-width:745px)': {
    fontSize: '1.5rem',
  },
  '@media (max-width:480px)': {
    fontSize: '1.5rem',
  },
  '@media (max-width:400px)': {
    fontSize: '1.2rem',
  },
};

export const lightyellow = "#C7D398"
export const mustardyellow = "#E1AD01";
export const blue = "#1E3246";
export const lightblue = "#A2C1C4";

//   typography
export const Title = ({children, color})=>{
    return <Typography style={{ fontWeight: 800,
      fontSize: '1.8rem',
      paddingTop: "20px",
      lineHeight: 1.0,
      textTransform: "uppercase",
      color: color?color:"inherit"}}>{children}</Typography>
}

export const SubTitle = ({children, color})=>{
    return <Typography variant="h2" style={{textTransform: "uppercase", color: color?color:"inherit"}}>{children}</Typography>
}

export const LeadingText = ({children, color})=>{
  return <Typography component={'div'} variant="body1" style={{fontWeight: "550", fontSize: "0.9rem", color: color?color:"inherit"}}>{children}</Typography>
}

export const Text = ({children, color})=>{
  return <Typography component={'div'} variant="body1" style={{fontWeight: "300", color: color?color:"inherit"}}>{children}</Typography>
}

export const TextBlock = ({color, description})=>{
  return <Text color={color}>
    <BlockContent blocks={description} />
</Text>
}

export const LeadingTextBlock = ({color, description})=>{
  return <LeadingText color={color}>
    <BlockContent blocks={description} />
</LeadingText>
}

export const Icon = ({filter})=>{
  return <FilteredSVG name={MeetecIcon} filter={filter} />
}

export const Banner = ({filter})=>{
  return <FilteredSVG name={MeetecBanner} filter={filter} />
}

export const BannerLogo = ({filter})=>{
  return <FilteredSVG name={MeetecBannerLogo} filter={filter} />
}

export const FilteredSVG = ({name, filter})=>{
  return <img src={name} width={"60px"} style={{textAlign:"left", width: "100%"}} className={filter}/>
}

// button
export const Button = ({children, to, color, external})=>{
  color = color?color:defaultTheme.palette.primary;
  const B = <MButton  color="primary" variant="hard" style={{color:color, borderColor: color, textDecoration: "none", margin: "5px"}}>
  {children}
</MButton>

  if (to){
    if (external){
      return <a href={to} target="_blank">{B}</a>
    } else {
      return <Link to={to}>
        {B}
    </Link>
    }
  } else {
    return B;
  }
}

//   media 
export const ResponsiveImage = ({src})=>{
    return <img src={src} style={{width: "100%"}} />
}

export const ImageWithCaption = ({src, caption})=>{
  return <div style={{width: "100%"}}>
    <ResponsiveImage src={src} />
    {caption?<b style={{color: lightyellow}}><i>{caption}</i><br/><br/></b>:null}
  </div>
}

export const Space = ()=><>&nbsp;</>

// contianers
export const Box = ({children})=>(
  <div style={{margin: "50px"}}>
    {children}
  </div>
)
  
export default theme;

